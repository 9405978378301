// Sprite :: Structure

.sprite {
	display: inline-block;
	background-image: url('../images/icons/sprite.png');
	background-repeat: no-repeat;
	vertical-align: text-top;
	background-size: 2000px;
}

.sprite {
	&.back-top {
		width: 50px;
		height: 50px;
	}

	&.back-top {
		background-position: 0 0;
	}

	&.facebook-large,
	&.instagram-large,
	&.linkedin-large {
		width: 60px;
		height: 60px;
	}
	&.facebook-small,
	&.instagram-small,
	&.linkedin-small {
		width: 30px;
		height: 30px;
	}

	&.facebook-large {
		background-position: -50px 0;
	}

	&.linkedin-large {
		background-position: -110px 0;

	}

	&.instagram-large {
		background-position: -170px 0;
	}

	&.facebook-small {
		background-position: -110px -60px;
	}

	&.linkedin-small {

		background-position: -50px -60px;
	}

	&.instagram-small {
		background-position: -80px -60px;
	}

	&.bubble-orange,
	&.bubble-white {
		width: 110px;
		height: 100px;
	}

	&.bubble-white {
		background-position: 0 -90px;
	}

	&.bubble-orange {
		background-position: -110px -90px;
	}

	&.bubble-blue {
		width: 383px;
		height: 440px;
		background-position: -229px 0;
	}

	&.bubble-orange-bis {
		width: 210px;
		height: 210px;
		background-position: -611px 0;
	}

	&.icon-engagment-1,
	&.icon-engagment-2,
	&.icon-engagment-3 {
		width: 150px;
		height: 160px;
	}

	&.icon-engagment-1 {
		background-position: -820px 0;
	}
	&.icon-engagment-2 {
		background-position: -820px -160px;
	}
	&.icon-engagment-3 {
		background-position: -820px -320px;
	}

	&.icon-activity-1,
	&.icon-activity-2,
	&.icon-activity-3,
	&.icon-activity-4 {
		width: 95px;
		height: 90px;
	}

	&.icon-activity-1 { background-position: -980px 0;}
	&.icon-activity-2 { background-position: -1080px 0;}
	&.icon-activity-3 { background-position: -1180px 0;}
	&.icon-activity-4 { background-position: -1280px 0;}

}
