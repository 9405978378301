// Font family
$font-stack: 'Roboto', sans-serif;

html {
    color: $bk-a;
    font-size: #{$font-size}px !important;
}

body {
    font-family: $font-stack !important;
    transition: opacity .1s ease-in-out;
}

// Title :: soft reset

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}

p {
    margin: 0;
}
// Title hierarchy :: Bigger to smaller

.h-like-a {
    font-size: 54px;
    font-weight: 500;
    line-height: 1.2em;
}
.h-like-b {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.1em;
}
.h-like-c {
    font-size: 38px;
    font-weight: 400;
    line-height: 1.1em;
}
.h-like-d {
    font-size: 28px;
    font-weight: 400;
    line-height: 1.2em;
    font-style: italic;
}
.h-like-e {
    font-size: 31px;
    font-weight: 500;
    line-height: 1.2em;
}
.h-like-f {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.2em;
}

@include media-breakpoint-down(lg) {
    .h-like-e {
        font-size: 28px;
    }
}
@include media-breakpoint-down(md) {
    .h-like-a {
        font-size: 50px;
    }
    .h-like-b {
        font-size: 40px;
    }
    .h-like-d {
        font-size: 24px;
    }
    .h-like-f {
        font-size: 30px;
    }
}
@include media-breakpoint-down(sm) {
    .h-like-a {
        font-size: 42px;
    }
    .h-like-b {
        font-size: 38px;
    }
    .h-like-c {
        font-size: 28px;
    }
    .h-like-e {
        font-size: 26px;
    }
    .h-like-f {
        font-size: 28px;
    }

}
@include media-breakpoint-down(xs) {
    .h-like-a {
        font-size: 36px;
    }
    .h-like-b {
        font-size: 34px;
    }
    .h-like-c {
        font-size: 24px;
    }
    .h-like-d {
        font-size: 22px;
    }
    .h-like-f {
        font-size: 26px;
    }

}
// Paragraph hierarchy :: Bigger to smaller

.p-like-a {
    font-size: 15px;
    font-size: 400;
}
@include media-breakpoint-down(lg) {
    .p-like-a {}
}
@include media-breakpoint-down(md) {
    .p-like-a {}
}
@include media-breakpoint-down(sm) {
    .p-like-a {}
}
@include media-breakpoint-down(xs) {
    .p-like-a {}
}
