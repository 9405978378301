// Reset :: Button & Links

button {
	background-color: transparent;
	border: none;
	padding: 0;
	outline: none !important;
	cursor: pointer;
}

a,
a:focus,
a:hover {
	text-decoration: none;
	cursor: pointer;
	color: inherit;
}
// Hover :: Link

.td-und-hover-a:focus,
.td-und-hover-a:hover {
	text-decoration: underline;
}

.td-und-hover-b:focus > *,
.td-und-hover-b:hover > * {
	text-decoration: underline;
}
// Buttons :: List

.btn-a {
	display: inline-block;
	border-radius: 10px;
	font-weight: 400;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 1.2em;
	padding: 15px 25px;
	text-align: center;
	transition: all 0.2s ease-in-out;
	color: $wh-a !important;

	&.except-submit {
		padding-left: 40px;
		padding-right: 40px;
	}

	&.orange {
		background-color: $or-a;
		&:focus,
		&:hover {
			background-color: tint($or-a, 20%);
		}
	}

	&.blue {
		background-color: $bl-a;
		&:focus,
		&:hover {
			background-color: tint($bl-a, 20%);
		}
	}

	&:focus,
	&:hover {}
}
// Button :: Hamburger

.btn-mobile-hamburger {
	display: block;
	margin: 0 auto;
	width: 30px;
	height: 20px;
	position: relative;
	z-index: 2;
	transform: rotate(0deg);
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	span {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background: $wh-a;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: all 0.2s ease-in-out;
	}

	span:nth-child(1) {
		top: 0;
	}

	span:nth-child(2),
	span:nth-child(3) {
		top: 9px;
	}

	span:nth-child(4) {
		top: 18px;
	}
}
// Button :: Hamburger Open

.menu-open .btn-mobile-hamburger {
	span:nth-child(1) {
		top: 18px;
		width: 0;
		left: 50%;
	}

	span:nth-child(2) {
		transform: rotate(45deg);
	}

	span:nth-child(3) {
		transform: rotate(-45deg);
	}

	span:nth-child(4) {
		top: 18px;
		width: 0;
		left: 50%;
	}
}
// Button :: Top

#btn-back-top {
	@include position(fixed, null 30px 30px null);
	z-index: 99;
	transition: all 0.2s ease-in-out;

	&:focus,
	&:hover {}
	@include media-breakpoint-down(sm) {
		right: 15px;
		bottom: 15px;
	}
}



.pagination-wp {
	margin-top: 35px;
	@include media-breakpoint-down(sm) {
		margin-top: px(15);
	}

	.pagination {
		justify-content: center;

		.page-link {
			padding: px(6) px(11);
			border: none !important;
			color: $bl-a;
			font-weight: 400;
			font-size: px(15);
		}

		.page-item.active .page-link {
			background-color: $or-a;
			color: $wh-a;
		}
	}
}
