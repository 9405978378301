// Body
body {
	overflow: hidden;
	overflow-y: auto;
}

body,
html {
	width: 100%;
	background-color: #fff;
}
html {
	overflow-x: hidden;
}
// Utils :: Image renderer optimize

.renderer-optmimize {
	image-rendering: -moz-crisp-edges;
	image-rendering: -moz-crisp-edges;
	image-rendering: -o-crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	-ms-interpolation-mode: nearest-neighbor;
}
// Utils :: Hide play button on video iphone

*::-webkit-media-controls-start-playback-button {
	display: none !important;
	-webkit-appearance: none;
}
// Components :: Magnific popup

.mfp-close {
	width: 30px !important;
	height: 30px;
	line-height: 30px;
	position: absolute;
	right: 0;
	top: 5px;
	text-decoration: none;
	text-align: center;
	opacity: .65;
	filter: alpha(opacity=65);
	padding: 0 0 18px 10px;
	color: #FFF;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
	display: block;
	background-image: url('../images/icons/sprite-popup.png') !important;
	background-position: -60px 0 !important;
	text-indent: 999em;
	overflow: hidden!important;
	transition: all .2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow {
	position: absolute;
	opacity: .65;
	filter: alpha(opacity=65);
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 30px;
	height: 60px;
	display: block;
	background-image: url('../images/icons/sprite-popup.png') !important;
	transition: all .2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow-left {
	left: 20px;
	background-position: 0 0 !important;
}

.mfp-arrow-right {
	right: 20px;
	background-position: -30px 0 !important;
}

.mfp-arrow:after,
.mfp-arrow:before {
	display: none !important;
}

.mfp-zoom-out .mfp-with-anim {
	opacity: 0;
	transform: scale(1.3);
	transition: all .2s ease-in-out;
}

.mfp-zoom-out.mfp-bg {
	opacity: 0;
	transition: all .2s ease-in-out;
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
	opacity: 1;
	transform: scale(1);
}

.mfp-zoom-out.mfp-ready.mfp-bg {
	opacity: .8;
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
	transform: scale(1.3);
	opacity: 0;
}

.mfp-zoom-out.mfp-removing.mfp-bg {
	opacity: 0;
}
// Components :: Slick

.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	margin-top: px(15);
	text-align: center;

	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 4px;
	}

	button {
		width: 38px;
		height: 8px;
		margin-right: 5px;
		background-color: $wh-a;
		border-radius: px(18);
		transition: all .2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active button {
		background-color: $or-a;
	}

	button:focus,
	button:hover {
		background-color: $or-a;
	}
}
// Components :: WOW

.fadeIn,
.fadeInDown,
.fadeInLeft,
.fadeInRight,
.fadeInUp {
	visibility: hidden;
}
// Function :: Equal Col
@include media-breakpoint-down(md) {
	.equal-col-item {
		min-height: 0 !important;
	}
}
// Component :: Google map

.map-wrapper {
	position: relative;
	height: 300px;
	background-color: #e5e3df;

	.map-canvas {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}
// Component :: Parallax

.parallax-mirror {
	z-index: 0 !important;
}

.parallax-wp {
	@extend .clearfix;
	overflow: hidden;
	position: relative;
	height: 300px;
	background: transparent;
}
@include media-breakpoint-down(sm) {}
@include media-breakpoint-down(xs) {}

#slick-home-intro-wp {
	> * {
		outline: none !important;
	}

	#slick-home-intro-arrows {
		.arrow-next,
		.arrow-prev {
			position: absolute;
			top: 50%;
			margin-top: -45px;
			transition: all .2s ease-in-out;
		}

		.arrow-prev {
			left: 0;
			transform: scale(0.75) translateX(-10px);

			&:focus,
			&:hover {
				transform: scale(0.8) translateX(-7px);
			}
		}

		.arrow-next {
			right: 0;
			transform: scale(0.75) translateX(10px);

			&:focus,
			&:hover {
				transform: scale(0.8) translateX(7px);
			}
		}
	}

	.slick-dots {
		position: absolute;
		width: 100%;
		text-align: center;
		bottom: 0;
		padding-bottom: px(20);
	}

	.item {
		background-size: cover;
		height: calc(100vh - 81px);
		&:after {
			position: absolute;
			top: 0;
			left: 0;
			content: '';
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,.3);
		}

		.container {
			height: 100%;
			display: flex;
			position: relative;
			z-index: 1;
			align-items: center;

			.content-wp {
				color: $wh-a;
				width: 50%;

				.txt {
					font-size: px(18);
					margin-top: px(15);
				}

				.btn-wp {
					display: block;
					margin-top: px(25);
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		.item {
			.container {
				.content-wp {
					width: 90%;
				}
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.slick-dots {
			position: relative;
			bottom: auto;
			left: auto;
			padding-top: px(10);
		}

		#slick-home-intro-arrows {
			display: none;
		}

		.slick-dots {
			button {
				background-color: $gy-a;
			}
			.slick-active button {
				background-color: $or-a;
			}
		}

		.item {
			position: relative;
			height: auto !important;
			min-height: 0 !important;
			padding: px(30) 0;

			.container {
				.content-wp {
					width: 100%;
					padding-left: 0;

					.txt {
						font-size: px(15);
					}

					.btn-wp,
					.txt {
						margin-top: px(10);
					}
				}
			}
		}
	}
	@include media-breakpoint-down(xs) {
		.slick-dots {
			transform: scale(0.8) translateY(-7.5px);
			padding-bottom: px(5);
		}

		.item .container {
			.content-wp {
				padding: 15px 30px;
				.txt {
					font-size: px(14);
				}
			}
		}
	}

	.slick-slide .content-wp {
		opacity: 0;
		transform: translateY(0) scale(1.1);
		transition: all .2s ease-in-out .3s;

		.btn-wp {
			opacity: 0;
			transform: translateX(-30px) scale(.8);
			transition: all .1s ease-in-out .5s;
		}
	}

	.slick-slide.animation .content-wp {
		opacity: 1;
		transform: translateY(0) scale(1);

		.btn-wp {
			opacity: 1;
			transform: translateX(0px) scale(1);
		}
	}
}

.bg-home-presentation {
	position: relative;
	padding: 100px 0;
	background-color: $bl-a;

	.bubble-white {
		position: absolute;
		bottom: -150px;
		left: 30%;
	}

	.container {
		position: relative;
		z-index: 1;
	}

	.border-separator {
		padding: 50px 0 50px 60px;
		border-left: 1px solid $wh-a;
	}

	&:after {
		opacity: .1;
		content: '';
		background-image: url('../images/illustrations/bg-presentation.png');
		width: 2585px;
		height: 464px;
		background-repeat: no-repeat;
		display: block;
		position: absolute;
		top: 0;
		right: 0;
	}

	@include media-breakpoint-down(md) {
		padding: 50px 0;
		.logo {
			max-width: 300px;
			display: block;
			margin: 0 auto 20px;
		}
		.border-separator {
			border-left: 0;
			border-top: 1px solid $wh-a;
			padding: 25px 0 0;
		}
		.bubble-white {
			display: none;
		}
	}
	@include media-breakpoint-down(sm) {
		.logo {
			max-width: 250px;
		}
	}
}

.bg-home-bus {
	background-color: $bl-b;
	color: $wh-a;
	position: relative;
	z-index: 2;
	.img-bus {
		position: relative;
		top: -30px;
		@include media-breakpoint-down(md) {
			top: auto;
			margin-top: 30px;
		}
	}
}

.bg-home-city {
	position: relative;
	@include media-breakpoint-down(md) {
		.content {
			margin-left: auto;
			margin-right: auto;
			max-width: 540px;
		}
	}
}

.bg-home-engagment {
	position: relative;
	padding: 100px 0;
	background-color: $bl-b;
	color: $wh-a;

	.container {
		position: relative;
		z-index: 1;
	}

	.img-engagment {
		position: absolute;
		width: 700px;
		right: 0;
		margin-top: -260px;
		top: 50%;
		z-index: 2;
		@media (max-width: 1500px) {
			width: 600px;
			margin-top: -200px;
		}
		@include media-breakpoint-down(lg) {
			width: 500px;
			margin-top: -170px;
		}
		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	.item {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}
		.content {
			padding-left: 30px;
			width: 35%;
		}
		&:nth-child(2) {
			margin-left: 60px;
		}
		&:nth-child(3) {
			margin-left: 120px;
		}
	}

	&:after {
		opacity: .8;
		content: '';
		background-image: url('../images/illustrations/bg-engagment.png');
		width: 1586px;
		height: 747px;
		background-repeat: no-repeat;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}

	@include media-breakpoint-down(md) {
		padding: 50px 0;
		.item {
			.content {
				width: calc(100% - 150px);
			}
			margin-left: 0 !important;
		}
	}
	@include media-breakpoint-down(xs) {
		padding: 30px 0;
		.item {
			display: block;
			text-align: center;
			margin-bottom: 30px;
			.content {
				margin-top: 10px;
				padding-left: 0;
				width: 100%;
			}
		}
	}
}

.bg-home-news {
	background-color: $bl-b;
	padding: 100px 0;
	position: relative;
	z-index: 1;
	margin-top: -50px;
	min-height: 750px;

	@include media-breakpoint-down(md) {
		padding: 75px 0 50px;
	}

	.bubble-orange {
		position: absolute;
		z-index: 2;
		left: 50%;
		margin-left: -400px;
		@include media-breakpoint-down(md) {
			display: none;
		}

	}

	&:after {
		opacity: .8;
		content: '';
		background-image: url('../images/illustrations/bg-news.png');
		width: 2585px;
		height: 730px;
		background-repeat: no-repeat;
		display: block;
		position: absolute;
		top: 0;
		right: 0;
	}
	.container {
		position: relative;
		z-index: 1;
	}

	&.inner-page {
		padding: 50px 0;
		margin-top: 0;
		min-height: auto;
		overflow: hidden;
	}
}

#slick-home-middle-wp {
	position: relative;
	z-index: 2;
}

.blog-item {
	height: 100%;
	background-color: $wh-a;
	.link {
		display: block;
		&:hover {
			.cat {
				color: $or-a;
			}
		}
	}
	.content {
		padding: 15px;
		.date {
			font-size: 11px;
			color: $gy-b;
		}
		.cat {
			font-size: 13px;
			margin-top: 20px;
			color: $bk-a;
			font-weight: 600;
			transition: all .2s ease-in-out;
		}
		.title {
			margin-top: 20px;
			font-size: 15px;
			color: $gy-b;
		}
	}
}

.bg-home-contact {
	position: relative;

	.form-wp {
		position: relative;
		z-index: 2;
		margin-top: 100px;
		padding: 60px 30px 50px;
		background-color: $wh-a;
		@include media-breakpoint-down(sm) {
			padding: 20px;
		}
	}

	.bubble-blue,
	.bubble-orange-bis {
		position: absolute;
		z-index: 2;
	}

	.bubble-blue {
		left: 50%;
		top: 80px;
		margin-left: -550px;
	}
	.bubble-orange-bis {
		left: 50%;
		top: 600px;
		margin-left: 450px;
	}

	&:after {
		content: '';
		background-image: url('../images/illustrations/bg-contact.jpg');
		width: 2585px;
		height: 851px;
		position: relative;
		background-repeat: no-repeat;
		margin: -525px auto 0;
		display: block;
	}

	@include media-breakpoint-down(md) {
		.form-wp {
			margin-top: 50px;
		}
		&:after {
			left: -200px;
		}
	}

	@include media-breakpoint-down(sm) {
		.bubble-blue,
		.bubble-orange-bis {
			display: none;
		}

		&:after {
			left: -400px;
		}

		.form-wp {
			margin-top: 20px;
		}
	}
}

.form-contact-wp {
	position: relative;
	.bubble-blue,
	.bubble-orange-bis {
		position: absolute;
		z-index: 0;
	}

	.bubble-blue {
		left: -200px;
		top: 50px;
	}
	.bubble-orange-bis {
		right: -150px;
		bottom: 50px;
	}
	.form {
		position: relative;
		z-index: 2;
		background-color: $wh-a;
		display: block;
		padding: 30px;
		box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.1);
		@include media-breakpoint-down(sm) {
			padding: 20px;
		}
	}
	@include media-breakpoint-down(sm) {
		.bubble-blue,
		.bubble-orange-bis {
			display: none;
		}
	}
}

.list-contact .item {
	position: relative;
	padding-left: 35px;
	font-weight: 400;
	color: $bk-a !important;
	font-size: px(14);
	line-height: 1.3em;
}

.list-contact .item:not(:last-child) {
	margin-bottom: 15px;
}

.list-contact .item .icon {
	font-size: px(20);
	position: absolute;
	color: $or-b;
	top: 50%;
	margin-top: -9px;
	left: 0;
}

.blog-item {
	height: 100%;
	border-radius: 10px;
	overflow: hidden;
	.link-wp {
		background-color: tint($gy-a, 50%);
		display: flex;
		flex-flow: column wrap;
		height: 100%;
		justify-content: space-between;

		.img-wp {
			position: relative;
			overflow: hidden;
			img {
				display: block;
				transition: all .2s ease-in-out;
			}
		}
		.content {
			padding: px(30) px(30) px(5);
			position: relative;

			> * {
				position: relative;
				z-index: 2;
			}

			.category-label {
				background-color: $or-a;
				color: $wh-a;
				line-height: 1em;
				display: inline-block;
				padding: px(6) px(8);
				color: $bk-a;
				margin-bottom: px(15);
				text-transform: uppercase;
				font-weight: 600;
				font-size: px(13);
				transition: all .2s ease-in-out;
			}

			.title {
				border-top: 2px solid $bk-a;
				padding-top: px(10);
				font-size: px(18);
				font-weight: 600;
				line-height: 1.3em;
				color: $bk-a;
			}

			.txt {
				font-size: px(12.5);
				line-height: 1.6em;
				margin-top: px(5);
				color: $bk-a;
			}
			.date {
				font-size: px(12);
				margin: px(5) 0 0;
			}
		}

		.btn-wp {
			text-align: right;
			padding: px(0) px(30) px(25);
			display: block;
			.link {
				font-weight: 600;
				display: inline-block;
				padding: px(5) px(10);
				font-size: px(12);
				background-color: $wh-a;
				color: $bl-a;
				transition: all .2s ease-in-out;
			}
		}

		&:focus,
		&:hover {
			.img-wp {
				img {
					transform: scale(1.1);
				}
			}
			.content {
				.category-label {
					background-color: $bk-a;
					color: $wh-a;
				}
			}
			.link {
				background-color: $or-a !important;
				color: $bk-a;
			}
		}

		&.except {
			.content:after {
				left: auto;
				right: 0;
				//@extend .decoration-bg-corner-right;
			}
		}

		&.except-portfolio {
			.content {
				padding: px(20) px(20) px(10);

				&:after {
					display: block;
					transform: scale(0.8) translate(-20px, -13px);
				}
			}
		}

	}
}

#slick-blog-bg {
	position: relative;
	padding: px(50) 0;
	background-image: url('../images/illustrations/bg-home-middle-1.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	&:after {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0,0,0,.3);
	}
	@include media-breakpoint-down(md) {
		padding: px(30) 0;
	}
	@include media-breakpoint-down(xs) {
		padding: px(20) 0 px(30);
	}
}

#slick-blog-wp {
	position: relative;
	width: calc(100% + 20px);
	margin-left: px(-10);
	z-index: 1;

	.slick-slide {
		outline: none !important;
	}

	.item {
		padding: px(10);
	}

	.link {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		color: $bl-a;
		font-weight: 600;
		border-radius: 30px;
		background-color: $wh-a;
		box-shadow: 0 0 10px rgba(0,0,0,.3);
		height: px(55);
		font-size: px(13.5);
		line-height: 1.15em;
		padding: px(5) px(25);
		outline: none !important;
		text-align: center;
		transition: all .2s ease-in-out;

		&.active {
			background: $or-b !important;
			border-color: $or-b !important;
			color: $wh-a !important;
		}

		&:hover {
			// background-color: tint($gold-a, 50%);
			// color: shade($gold-b, 30%);
			background: $wh-a;
			border-color: $bl-a;
		}

		&:hover {
			transform: scale(1.05);
		}
	}
	@include media-breakpoint-down(md) {
		.link {
			font-size: px(13.5);
		}
	}
	@include media-breakpoint-down(sm) {

		.link {
			font-size: px(14.5);
		}

	}
	@include media-breakpoint-down(xs) {

		.link {
			font-size: px(12);
		}

	}

	.slick-dots {
		margin-bottom: 0;
		text-align: center;
		position: absolute;
		bottom: px(-35);
		width: 100%;
		@include media-breakpoint-down(md) {
			bottom: -30px;
		}
	}
}

.category-label {
	display: inline-block;
	background-color: $wh-a;
	color: $bl-b;
	font-size: 12px;
	font-weight: 500;
	border-radius: 10px;
	padding: 5px 10px;
	line-height: 1em;
}

.portfolio-item {
	.link {
		display: block;
		overflow: hidden;
		border-radius: 100%;
		position: relative;
		border: 10px solid tint($gy-a, 50%);
		transition: all .2s ease-in-out;

		.caption,
		.img-fluid {
			transition: all .2s ease-in-out;
		}

		@include media-breakpoint-up(sm) {
			&:hover {
				border-color: $wh-a;
				box-shadow: 0 0 15px rgba(0, 0, 0, .2);
				.img-fluid {
					transform: scale(1.2);
				}
				.caption {
					opacity: 1;
				}
			}

		}

		.caption {
			opacity: 0;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			display: flex;
			flex-flow: column wrap;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 10px 20px;
			color: $wh-a;
			background-color: rgba(0, 0, 0, .75);

			.title {
				font-weight: 400;
				text-transform: uppercase;
				font-size: 15px;
				line-height: 1.2em;
				letter-spacing: 1px;
			}

			.tag {
				background-color: $or-b;
				color: $wh-a;
				line-height: 1em;
				padding: 3px 6px;
				margin-top: 5px;
				font-size: 11px;
				border-radius: 3px;

			}

		}

		@include media-breakpoint-down(sm) {
			.caption {
				justify-content: flex-end;
				padding: 20px 40px 30px;
				opacity: 1;
				height: auto;
				.tag {
					display: block;
					max-width: 60%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}

}

.portfolio-navigation {
	position: relative;

	.sprite.bubble-orange {
		position: absolute;
		top: -40px;
		left: -75px;
		z-index: 1;
	}

	.list {
		border: 1px solid $gy-a;
		padding: px(20) px(30);
		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
		background-color: $wh-a;
		border-radius: 8px;
		position: relative;
		z-index: 2;
	}

	.title-cat {
		font-size: px(13);
		padding-bottom: 6px;
		color: $bl-b;
		margin-bottom: px(15);
		text-transform: uppercase;
		font-weight: 600;
		border-bottom: 1px solid shade($gy-b, 50%);
	}

	.item {
		margin-top: px(5);
	}

	.list .item:first-child {
		margin-top: 0;
	}

	.link {
		position: relative;
		font-weight: 400;
		font-size: px(14);
		color: $bl-b;
		display: block;
		padding-left: px(20);
	}

	.link:before {
		content: '';
		display: block;
		position: absolute;
		top: 8px;
		left: 0;
		border-radius: 100%;
		width: 5px;
		height: 5px;
		background-color: $bl-b;
		transition: all .2s ease-in-out;
	}

	.link.active {
		color: $or-b !important;
		font-weight: 600;
	}

	.link.active:before {
		border-color: $or-b !important;
		background-color: $or-b !important;
	}

	.link:focus,
	.link:hover {
		color: $or-b;
	}

	.link:focus:before,
	.link:hover:before {
		background-color: $or-b;
	}

	@include media-breakpoint-down(sm) {
		.sprite.bubble-orange {
			display: none;
		}
		.list {
			border: none;
			box-shadow: 0 0 20px rgba(0,0,0,0.1);
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}

}

.activity-print-wp {
	text-align: center;
	.title {
		margin-top: 10px;
		color: $bl-a;
		font-size: 17px;
		font-weight: 500;
		line-height: 1.3em;
	}
}

.activity-print-tags {
	text-align: center;
	li {
		margin: 0 5px 10px 0;
		display: inline-block;
		font-size: 16px;
		border-radius: 3px;
		background-color: $bl-a;
		line-height: 1em;
		padding: 10px 12px;
		color: $wh-a;

		// &:not(:last-child){
		// 	&:after {
		// 		content: "-";
		// 		margin-left: 3px;
		// 	}
		// }
	}
}

.activity-portfolio-wp {
	width: 100%;
	display: flex;
	align-items: center;

	.bubble-left,
	.bubble-right {
		position: absolute;
		top: 0;
	}

	.bubble-left {
		left: -200px;
	}
	.bubble-right {
		right: -200px;
	}

	.col-img {
		display: block;
		width: 275px;
		height: 275px;
		position: relative;
		border-radius: 100%;
		background-color: $bk-a;
		overflow: hidden;
		border: 12px solid tint($gy-a, 50%);
		z-index: 1;
		&:after {
			content: '+';
			color: $wh-a;
			font-weight: 400;
			opacity: 0;
			font-size: 80px;
			position: absolute;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			left: 0;
			width: 100%;
			height: 100%;
			transition: all .2s ease-in-out;
		}
		&:hover {
			&:after {
				opacity: 1;
			}
			img {
				transform: scale(1.2);
				opacity: .5;
			}
		}
		img {
			transition: all .2s ease-in-out;
		}
	}
	.col-txt {
		width: calc(100% - 275px);

		.title {
			font-size: 24px;
			text-transform: uppercase;
			font-weight: 600;
			margin-bottom: 15px;
			color: $bl-a;
		}
		.title-sub {
			font-size: 16px;
			font-weight: 500;
			color: $bl-a;
			margin-top: 20px;
		}

		> p:not(:last-child) {
			margin-bottom: 10px;
		}

		ul {
			li {
				position: relative;
				padding-left: 15px;
				&:before {
					content: '';
					position: absolute;
					top: 7px;
					left: 0;
					width: 6px;
					height: 6px;
					background-color: $bl-a;
					border-radius: 10px;
				}
			}
		}
	}

	@include media-breakpoint-down(lg) {
		.bubble-left,
		.bubble-right {
			display: none;
		}
	}

	@include media-breakpoint-down(md) {
		flex-flow: column;
		.col-img {
			width: 250px;
			height: 250px;
			order: 0;
		}
		.col-txt {
			order: 1;
			width: 100%;
			margin-top: 20px;
			.title {
				text-align: center;
				margin-bottom: 30px;
			}
		}
	}
}

.activity-portfolio-cta {
	display: block;
	height: 300px;
	width: 100%;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: center 0;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	@include media-breakpoint-down(md) {
		height: 200px;
	}

	.btn-a {
		position: relative;
		z-index: 2;
	}

	&:after {
		content: '';
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .5);
		position: absolute;
		top: 0;
		left: 0;
	}
}

.background-bus-numerics {
	position: relative;
	.img-text {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
}

.background-bus-network {
	position: relative;
	background: #79b9e6;
	background: -moz-linear-gradient(left, #79b9e6 0%, #0f509f 100%);
	background: -webkit-linear-gradient(left, #79b9e6 0%,#0f509f 100%);
	background: linear-gradient(to right, #79b9e6 0%,#0f509f 100%);
	.img-text {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
}

.activity-bus-links {
	width: 100%;
	display: flex;
	li {
		flex: 0 0 33.33%;
		padding: 0 10px;
	}
	a {
		background-color: $or-b;
		display: flex;
		align-items: center;
		border-bottom: 2px solid shade($or-b, 20%);
		justify-content: center;
		line-height: 1.2em;
		font-size: 14px;
		height: 100%;
		border-radius: 10px;
		color: $wh-a;
		padding: 20px;
		text-align: center;
		transition: all .2 ease-in-out;
		box-shadow: 0 0 5px rgba(0, 0,0 ,0);

		&:hover {
			border-bottom-color: $or-b;
			box-shadow: 0 0 5px rgba(0, 0,0 ,.3);
		}
	}

	@include media-breakpoint-down(sm) {
		display: block;
		a {
			font-size: 13px;
		}
		li {
			margin-top: 10px;
		}
	}

}

.activity-bus-links-wp {
	position: relative;
	padding: 40px 0;
	background-image: url('../images/illustrations/bg-header-page.png');
	background-position: top center;
	background-color: $bl-a;
	text-align: center;
	color: $wh-a;
	@include media-breakpoint-down(sm) {
		padding: 20px 0;
	}
}
