// Footer
.footer-margin {
	position: relative;
	margin-top: -300px;
}
#footer {
	background-color: $bl-a;
	color: $wh-a;
	.link {
		font-size: 13px;
	}
	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {}
	@include media-breakpoint-down(sm) {}
}


.social-hover {
	a {
		display: block;
		transition: all 0.2s ease-in-out;
		&:hover {
			transform: translateY(-5px);
		}
	}
}
