// Header :: Fixed or small/large

body {
	padding-top: 0;
}

.anchor-scroll {
	display: block;
	margin-top: 0;
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: transparent;
}
@include media-breakpoint-down(lg) {
	body {
		padding-top: 0;
	}

	.anchor-scroll {
		margin-top: 0;
	}
}
// Header :: Small on scroll

body.header-small #header {}
@include media-breakpoint-up(lg) {
	body.header-small #header {}
}
// Navigation :: Force Display on large device
@include media-breakpoint-up(sm) {
	#nav-main {
		display: flex !important;
	}

	#nav-mobile-trigger {
		display: none;
	}
}
// Header :: Structure

#header {
	position: relative;
	z-index: 10;
	width: 100%;
	padding: 15px 0;
	background-color: $wh-a;
	transition: all .2s ease-in-out;

	.container-fluid {
		display: flex;
		justify-content: space-between;
		padding-left: 30px;
	}

	.col-right,
	.logo,
	.social {
		display: flex;
		align-items: center;
	}

	.logo {
		.separator {
			margin: 0 25px;
		}
	}

	.separator {
		height: 35px;
		width: 1px;
		background-color: $bk-a;
		display: inline-block;
		margin: 0 15px;
	}

	.social {
		margin-left: 5px;
		.item {
			margin-right: 15px;
			.link {
				display: block;
				transition: all .2s ease-in-out;
				&:hover {
					transform: translateY(-5px);
				}
			}
		}
	}
	@include media-breakpoint-down(lg) {
		.logo {
			.logo-ue,
			.separator {
				display: none;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.container-fluid {
			padding-left: 15px;
			padding-right: 10px;
		}
		.logo {
			.logo-smartplay {
				width: 150px;
			}
		}
		.social {
			.item {
				margin-right: 5px;
			}
		}
		.separator {
			margin: 0 5px;
		}
	}

	@include media-breakpoint-down(sm) {
		padding-top: 50px;
		padding-bottom: 10px;
		.container-fluid {
			padding-right: 0;
		}
		.social {
			justify-content: center;
			width: 100%;
			height: 40px;
			background-color: tint($gy-a, 50%);
			border-bottom: 1px solid $gy-a;
			position: absolute;
			top: 0;
			left: -5px;
			.item {
				position: relative;
				margin-top: -3px;
			}
		}
		.separator {
			display: none;
		}
	}

	@include media-breakpoint-down(xs) {
		padding: 0;
		.container-fluid {
			display: block;
			padding: 0;
		}
		.col-left {
			padding: 10px 0 7.5px 10px;
		}
		#nav-main {
			background-color: tint($gy-a, 70%);
			display: none;
			width: 100%;
			padding: 10px 0;
			border-top: 1px solid $gy-a;
			border-bottom: 1px solid $gy-a;
			> * {
				display: block;
				text-align: center;
			}
		}
		#nav-mobile-trigger {
			position: absolute;
			right: 0;
			top: 0;
			background-color: $bl-a;
			width: 56px;
			height: 56px;
		}
		.social {
			background: none;
			height: auto;
			border: 0;
			left: auto;
			top: 15px;
			width: auto;
			right: 70px;
		}
		.item[data-navsub] {
			.link {
				display: none !important;
			}
		}
		.nav-sub {
			display: block;
			background: none;
		}
	}
}

#nav-main {
	display: flex;
	align-items: center;

	.item {
		flex-grow: 1;
		padding-left: 40px;
		&:last-child {
			margin-right: 10px;
		}
	}

	.link {
		text-align: center;
		display: block;
		color: $bk-a;
		line-height: 1.2em;
	}

	.btn-link {
		display: inline-block;
		border-radius: 8px;
		font-weight: 400;
		font-size: 12px;
		line-height: 1em;
		padding: 11px 12.5px;
		transition: all .2s ease-in-out;
		color: $wh-a !important;
		background-color: $bl-a;

		&:focus,
		&:hover {
			background-color: tint($bl-a, 20%);
		}
	}

	.link:focus,
	.link:hover {
		color: $bl-a;
	}

	.link.active {
		color: $or-c;
	}

	.nav-sub {
		.item-sub {
			margin-bottom: 10px;
		}

		.link-sub {
			font-weight: 400;
			font-size: 13px;
			line-height: 1.1em;
			display: block;
			color: $bl-a;
			white-space: nowrap;
		}

		.link-sub:focus,
		.link-sub:hover {}

		.link-sub.active {}
	}
	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {
		.item {
			padding-left: 25px;
		}
		.link {
			font-size: 13px;
		}
	}
	@include media-breakpoint-down(xs) {
		.item,
		.item-sub {
			margin: 15px 0;
		}
		.link {
			font-weight: 500;
			font-size: 15px;
			line-height: 1.1em;
			display: block;
			color: $bl-a;
		}
		.btn-link {
			font-size: 15px;
		}
		.link-sub {
			font-size: 15px !important;
		}
	}

}
// Navigation :: Sub

.nav-sub {
	display: none;
	position: relative;
	top: auto;
	left: auto;
	height: auto;
	opacity: 1;
	background-color: $wh-a;
}

li[data-navsub] {
	position: relative;

	&.focus {
		.arrow {
			transform: rotate(180deg);
			border-color: $bl-a transparent transparent transparent;
		}
	}

	.link {
		display: flex !important;
		align-items: center;
	}

	.arrow {
		width: 0;
		height: 0;
		margin-left: 10px;
		border-style: solid;
		border-width: 5px 5px 0 5px;
		border-color: #1d1d1b transparent transparent transparent;
	}
}
@include media-breakpoint-up(sm) {
	li[data-navsub].focus > .nav-sub {
		visibility: visible;
		display: block;
		top: 25px;
		padding: 15px 20px 5px;
		height: auto;
		opacity: 1;
	}

	.nav-sub {
		display: none;
		position: absolute;
		left: 25px;
		border-radius: 5px;
		border: 1px solid tint($gy-a, 20%);
		z-index: 99;
		overflow: hidden;
		height: 0;
		width: calc(100% + 70px);
		padding: 0;
		opacity: 0;
		background-color: tint($gy-a, 70%);
		transition: all .1 ease-in-out;
		box-shadow: 0 4px 5px -1px rgba(0,0,0,0.2);
	}
}


.header-page {
	position: relative;
	padding: 40px 0;
	background-image: url('../images/illustrations/bg-header-page.png');
	background-position: top center;
	background-color: $bl-a;
	text-align: center;
	color: $wh-a;

	.h-like-a {
		display: inline-block;
		position: relative;
		&:after {
			content:"";
			display: block;
			width: 50px;
			height: 3px;
			margin: 10px auto 0;
			background-color: $wh-a;
		}
		&:before {
			content:'';
			@extend .sprite;
			@extend .bubble-white;
			transform: scale(.7);
			position: absolute;
			top: 0;
			left: -125px
		}
		@include media-breakpoint-down(md) {
			&:before {
				display: none;
			}
		}
	}

	@include media-breakpoint-down(md) {
		padding: 30px 0;
	}
	@include media-breakpoint-down(md) {
		padding: 25px 0;
	}

}
