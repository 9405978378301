// Custom variables :: Colors
//
// -> bk : black;
// -> gy : grey;
// -> wh : white;
// -> pu : purple;
// -> bl : blue;
// -> br : brown;
// -> or : orange;
// -> gn : green;
// -> pk : pink;
$bk-a: #1D1D1B;
$wh-a: #FFFFFF;
$gy-a: #E5E5E5;
$gy-b: #777776;
$gy-c: #F2F2F2;
$bl-a: #00879D;
$bl-b: #221D42;
$bl-c: #bae3f0;
$or-a: #EB490F;
$or-b: #F48105;
$or-c: #F6A701;

// Color
.c-bk-a {
  color: $bk-a;
}

.c-wh-a {
  color: $wh-a !important;
}
.c-gy-a {
  color: $gy-a;
}

.c-gy-b {
  color: $gy-b;
}

.c-gy-c {
  color: $gy-c;
}

.c-bl-a {
  color: $bl-a;
}

.c-bl-b {
  color: $bl-b;
}

.c-or-a {
  color: $or-a;
}

.c-or-b {
  color: $or-b;
}
.c-or-c {
  color: $or-c;
}

// Background
.bg-bk-a {
  background-color: $bk-a;
}

.bg-wh-a {
  background-color: $wh-a;
}

.bg-bl-a {
  background-color: $bl-a;
}

.bg-gy-a {
  background-color: $gy-a;
}

.bg-gy-b {
  background-color: $gy-b;
}
.bg-gy-c {
  background-color: $gy-c;
}

.bg-bl-a {
  background-color: $bl-a;
}

.bg-bl-b {
  background-color: $bl-b;
}

.bg-bl-c {
  background-color: $bl-c;
}
.bg-or-a {
  background-color: $or-a;
}

.bg-or-b {
  background-color: $or-b;
}
.bg-or-c {
  background-color: $or-c;
}
